import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../environments/environment';
import { SessionService } from '../shared';
import { NonceService } from './nonce.service';
import { Profile } from './profile/models/profile';
import { GoogleAnalyticsAccount } from '../shared/models/ga-account';
import { mergeMap, shareReplay, take } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable()
export class GoogleService {
  private baseUrl: string;

  constructor(private httpClient: HttpClient, private sessionService: SessionService, private nonce: NonceService) {
    this.baseUrl = environment.baseUrl;
  }

  public account(): Observable<Profile> {
    return this.sessionService.account
      .pipe(
        mergeMap(x => {
          return this.httpClient.get<Profile>(`${this.baseUrl}account/${x.id}/google`);
        }),
        shareReplay()
      );
  }

  public accounts(): Observable<GoogleAnalyticsAccount[]> {
    return this.sessionService.account
      .pipe(
        mergeMap(x => {
          return this.httpClient.get<GoogleAnalyticsAccount[]>(`${this.baseUrl}account/${x.id}/google/accounts`);
        }),
        shareReplay()
      );
  }

  public disconnect(): Observable<{}> {
    return this.sessionService.account
      .pipe(
        take(1),
        mergeMap(x => {
          // tslint:disable-next-line
          return this.httpClient.post(`${this.baseUrl}account/${x.id}/google/disconnect`, {});
        }),
        shareReplay()
      );
  }

  public complete(code: string): Observable<{}> {
    return this.sessionService.account
      .pipe(
        mergeMap(x => {
          return this.httpClient
            .post(
              `${this.baseUrl}account/${x.id}/google/exchange`,
              { Code: code, RedirectUrl: environment.google.callbackUrl }
            );
        })
      );
  }

  public accountSelect(analyticAccount: GoogleAnalyticsAccount): Observable<{}> {
    return this.sessionService.account
      .pipe(
        take(1),
        mergeMap(x => {
          return this.httpClient
            .post(`${this.baseUrl}account/${x.id}/google/account/select`,
              {
                profileId: analyticAccount.profileId,
                displayName: analyticAccount.displayName,
                analyticsType: analyticAccount.analyticsType
              });
        }),
        shareReplay()
      );
  }

  public connect(): void {
    const state = this.nonce.generate();
    // tslint:disable-next-line
    const url = `https://accounts.google.com/o/oauth2/auth?response_type=code&client_id=${environment.google.clientId}&access_type=offline&approval_prompt=force&redirect_uri=${environment.google.callbackUrl}&scope=${environment.google.scope}&state=${state}`;
    window.location.href = url;
  }
}
