<div id="custom-sidebar" class="d-flex flex-column h-100">
  <nav id="custom-sidebar-nav" [ngClass]="{'custom-sidebar-nav-with-header': hasExternalToolbar}">
    <div class="text-center">
      <a [routerLink]="sessionIsAuthorized ? ['/seo/dashboard'] : ['/']">
        <img class="img-fluid p-4" src="{{ siteConfig?.logoSmallUrl }}" />
      </a>
    </div>

    <ul class="nav justify-content-center" *ngIf="sessionIsReady">
      @if (featureVisibility[Features.SEO].hasFeature) {
        <li class="nav-item" *hideKey="'SEO'">
          <a class="narrow nav-link active text-light custom-menu-item clickable" (click)="toggleFeature(Features.SEO)" [ngClass]="{
              'highlight': featureVisibility[Features.SEO].isActive
              }">{{ 'navigation_seo' | translate }}</a>
        </li>
      }
      @if (featureVisibility[Features.BusinessProfileManagement].hasFeature) {
        <li class="nav-item" *hideKey="'BusinessProfileManagement'">
          <a class="narrow nav-link active text-light custom-menu-item clickable"
            (click)="toggleFeature(Features.BusinessProfileManagement)" [ngClass]="{
              'highlight': featureVisibility[Features.BusinessProfileManagement].isActive
            }">{{ 'navigation_business_profile_management' | translate }}</a>
        </li>
      }
      @if (featureVisibility[Features.StandaloneBacklink].hasFeature) {
        <li class="nav-item">
          <a class="narrow nav-link active text-light custom-menu-item clickable"
            (click)="toggleFeature(Features.StandaloneBacklink)" [ngClass]="{
              'highlight': featureVisibility[Features.StandaloneBacklink].isActive
            }">{{ 'navigation_backlink' | translate }}</a>
        </li>
      }
    </ul>

    <div *ngIf="sessionIsAuthorized">
      <ul class="nav flex-column justify-content-center text-center">
        @if (featureVisibility[Features.SEO].isActive) {
          <ng-container *hideKey="'SEO'">
            <li class="nav-item" *hideKey="'Seo-Dashboard'">
              <a class="nav-link text-light custom-menu-item" routerLink="/seo/dashboard" routerLinkActive="current"
                (click)="onNavigation()">{{ 'navigation_seo_dashboard' | translate }}</a>
            </li>
            <li class="nav-item" *hideKey="'Seo-Keywords'">
              <a class="nav-link text-light custom-menu-item" routerLink="/seo/keywords" routerLinkActive="current"
                (click)="onNavigation()">{{ 'navigation_seo_keywords' | translate }}</a>
            </li>
            <li class="nav-item" *hideKey="'Seo-Actions'">
              <a class="nav-link text-light custom-menu-item" routerLink="/seo/work-history" routerLinkActive="current"
                (click)="onNavigation()">{{ 'navigation_seo_work_history' | translate }}</a>
            </li>
            <li class="nav-item" *hideKey="'Seo-Analytics'">
              <a class="nav-link text-light custom-menu-item" routerLink="/seo/analytics" routerLinkActive="current"
                (click)="onNavigation()">{{ 'navigation_seo_analytics' | translate }}
                <ng-container *ngIf="!((account)?.hasGoogleAnalyticsAccess)">
                  <sup>
                    <i class="fa fa-circle text-danger status-icon"></i>
                  </sup>
                </ng-container>
              </a>
            </li>
          </ng-container>
        }
        @else if (featureVisibility[Features.StandaloneBacklink].isActive) {
          <li class="nav-item" *hideKey="'Seo-Actions'">
            <a class="nav-link text-light custom-menu-item" routerLink="/seo/work-history" routerLinkActive="current"
              (click)="onNavigation()">{{ 'navigation_seo_work_history' | translate }}</a>
          </li>
        }
        @if (featureVisibility[Features.BusinessCitations].hasFeature
        && featureVisibility[Features.SEO].isActive
        && !hideSeoListings) {
          <ng-container *hideKey="'Seo-Business-Citations'">
            <li class="nav-item">
              <a class="nav-link text-light custom-menu-item" routerLink="/business-citations/local-listings"
                routerLinkActive="current" (click)="onNavigation()">{{ 'navigation_business_citations' | translate
                }}</a>
            </li>
            <li class="nav-item">
              <a class="nav-link text-light custom-menu-item" routerLink="/business-citations/listings-sync-history"
                routerLinkActive="current" (click)="onNavigation()">{{ 'navigation_business_citations_sync_history' |
                translate }}</a>
            </li>
          </ng-container>
        } @else if (featureVisibility[Features.BusinessProfileManagement].isActive && !hideBusinessCitations) {
          <ng-container *hideKey="'BusinessProfileManagement'">
            <li class="nav-item">
              <a class="nav-link text-light custom-menu-item" routerLink="/business-citations/local-listings"
                routerLinkActive="current" (click)="onNavigation()">{{ 'navigation_business_citations' | translate }}</a>
            </li>
            <li class="nav-item">
              <a class="nav-link text-light custom-menu-item" routerLink="/business-citations/listings-sync-history"
                routerLinkActive="current" (click)="onNavigation()">{{ 'navigation_business_citations_sync_history' |
                translate }}</a>
            </li>
          </ng-container>
        }
      </ul>
    </div>
  </nav>
  <div class="mt-auto p-2">
    <div *ngIf="sessionIsReady" class="d-md-none mb-4">
      <div *ngIf="account" class="text-center">
        <h6 class="text-light border border-light border-end-0 border-start-0 border-top-0 p-2">{{ (account)?.name }}
        </h6>
        <span *hideKey="'Header-Profile'">
          <a class="nav-link text-light custom-menu-item" routerLink="/user/profile" *ngIf="sessionIsReady"
            (click)="onNavigation()">{{ 'profile' | translate }}</a>
        </span>
        <a (click)="switchAccounts()" href="#" *ngIf="canSwitchAccounts" class="nav-link text-light custom-menu-item">{{
          'switch_accounts' | translate }}</a>
        <a (click)="logoff()" href="#" class="nav-link text-light custom-menu-item">{{ 'log_off' | translate }}</a>
      </div>
    </div>
    <div *ngIf="siteConfig?.partnerName" class="text-center p-2">
      <span *hideKey="'Sidebar-CopyrightNotice'">
        <h6 class="text-light">&copy;{{ today | date:'yyyy' }} {{ siteConfig?.partnerName }}
          <br>
        </h6>
      </span>
      <div *hideKey="'Sidebar-CopyrightNoticeBlock'">
        <small class="text-light">{{ 'message_copyright_notice' | translate }}</small>
      </div>
      <div *hideKey="'Sidebar-PrivacyPolicy'">
        <a routerLink="/privacy-policy"><small class="text-light">{{ 'privacy_policy' | translate }}</small></a>
      </div>
      <div *hideKey="'Sidebar-Accessibility'">
        <ng-container *ngIf="isEnglishLanguage">
          <a routerLink="/accessibility-statement"><small class="text-light">Accessibility Statement</small></a>
        </ng-container>
      </div>
    </div>
    <div class="p-2">
      <app-language-selector *hideKey="'Sidebar-LanguageSelector'"></app-language-selector>
    </div>
  </div>
</div>