export enum CustomerAccountTypes {
    Yahoo = 1,
    Pinterest = 7,
    Blog = 8,
    GoogleAccount = 9,
    GoogleWebmasters = 10,
    GoogleAnalytics = 11,
    GoogleTagManager = 12,
    GoogleBusinessProfile = 13,
    BingLocal = 14,
    FTP = 15,
    DomainRegistrar = 16,
    BingAccount = 20,
    BingWebmastersAccount = 21,
    CMSCustomerAccess = 22,
    BlogHosting = 23,
    BlogAdmin = 24,
    BlogEditor = 25,
    WebsiteHosting = 26,
    CMSAdminAccess = 27
}
